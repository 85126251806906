import gql from 'graphql-tag'

export const EventFragment = gql`
  fragment PACEvent on PACEvent {
    id
    name
    dateRange
    slug
    ticketed
    boxOfficeUrl
    boxOfficeButtonAppearance {
      activeText
      eventPassedText
      noBoxOfficeUrlText
    }
    programUrl
    livestreamUrl
    liveNow
    embedLivestream
    organizer {
      name
      description
      website
      websiteLabel
      twitter
      facebook
      facebookLabel
      instagram
    }
    description
    promotionImage {
      title
      url
    }
    location {
      name
      address
      googlePlaceId
    }
    type
    status
    statusAlertText
    ribbon {
      text
      backgroundColor
      textColor
    }
  }
`
