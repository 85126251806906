import { useEffect, useState } from 'react'

type ScrollPosition = {
  x?: number
  y?: number
}

const useScrollPosition = (): ScrollPosition => {
  const [position, setPosition] = useState<ScrollPosition>({ x: null, y: null })

  useEffect(() => {
    const handleScroll = () => {
      setPosition({
        x: window.pageXOffset,
        y: window.pageYOffset
      })
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return position
}

export default useScrollPosition
