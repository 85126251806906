import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

export const initSentry = () => {
  const integrations = []
  if (process.env.NEXT_IS_SERVER === 'true') {
    if (process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: frame => {
            frame.filename = frame.filename.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
              'app:///'
            )
            frame.filename = frame.filename.replace('.next', '_next')
            return frame
          }
        })
      )
    }
  } else {
    integrations.push(new Integrations.BrowserTracing())
  }

  Sentry.init({
    enabled: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !== 'Local',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    integrations,
    dsn: 'https://a02682ec7e0e49dfa1fa38d18877b44f@o567715.ingest.sentry.io/5711967',
    release: process.env.NEXT_PUBLIC_COMMIT_SHA
  })
}
