import styled from 'styled-components'

const LiveBug = styled.div`
  content: '';
  width: 10px;
  height: 10px;
  background-color: #ea3737;
  border-radius: 50%;
  display: inline-block;

  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(234, 55, 55, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 8px rgba(234, 55, 55, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(234, 55, 55, 0);
    }
  }
`

export default LiveBug
