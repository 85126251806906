import DateTime from '../backend/date/date-time'
import { TextColor } from './text-color'

export enum BannerIcon {
  NONE = 'NONE',
  EXCLAMATION_CIRCLE = 'EXCLAMATION_CIRCLE'
}

export default interface Banner {
  icon: BannerIcon
  message: any
  backgroundColor: string
  textColor: TextColor
  startTime: DateTime
  endTime?: DateTime
}
