import React from 'react'
import styled from 'styled-components'
import PACEvent from '../../models/event/pac-event'
import { colors, transitionTimings } from '../../styles/theme'
import LiveBug from './live-bug'
import Link from 'next/link'
import { RightArrow } from '../system/icons'
import useScrollPosition from './use-scroll-position'
import { useBannerHeight } from '../system/banner/banner-provider'
import { useHeaderHeight } from '../header/header-provider'

const Container = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  background-color: ${colors.white};
  z-index: 200;
  padding: ${props => props.theme.spacing.base300} ${props => props.theme.spacing.base400};
  border-radius: 3px 0 0 3px;
  box-shadow: ${props => props.theme.shadows.close};
  font-weight: bold;
  max-width: 80%;
  line-height: normal;
  transition: transform ${transitionTimings.veryFast} linear;
`

const NotificationInner = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: space-between;
`

const StreamNotification = ({ event }: { event: PACEvent }) => {
  const navHeight = useHeaderHeight()
  const bannerHeight = useBannerHeight()
  const headerHeight = navHeight + bannerHeight
  const { x, y } = useScrollPosition()
  const yOffset = Math.max(headerHeight - y, 0)

  return (
    <Container
      style={{
        transform: `translateY(${yOffset}px)`
      }}
    >
      <Link href="/events/[slug]" as={`/events/${event.slug}`} className="unstyled">

        <NotificationInner>
          <LiveBug />
          {event.name}
          <RightArrow />
        </NotificationInner>

      </Link>
    </Container>
  );
}

export default StreamNotification
