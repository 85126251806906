import React from 'react'
import styled from 'styled-components'
import PACEvent from '../../models/event/pac-event'
import { colors } from '../../styles/theme'
import Subheadline from '../system/typography/subheadline'
import Link from 'next/link'
import EventLivestreamVideoPlayer from './event-livestream-video-player'

const StreamContainer = styled.div`
  width: 400px;
  background-color: ${colors.white};
  position: fixed;
  bottom: 0px;
  right: 20px;
  display: grid;
  grid-template-rows: auto 1fr;
  z-index: 200;
  box-shadow: ${(props) => props.theme.shadows.close};
  border-radius: 3px 3px 0 0;
  overflow: hidden;
`

const Title = styled.h5`
  margin: 0;
`

const Organizer = styled(Subheadline)`
  margin: 0;
`

const BottomCard = styled.section`
  display: grid;
  align-content: center;
  gap: ${(props) => props.theme.spacing.base100};
  padding: ${(props) => props.theme.spacing.base400} ${(props) => props.theme.spacing.base400};
  color: ${(props) => props.theme.colors.typography.dark.text};
  &:hover {
    color: ${(props) => props.theme.colors.typography.dark.linkHoverColor};
  }
`

const StreamMiniplayer = ({ event, ...other }: { event: PACEvent }) => {
  return (
    <StreamContainer {...other}>
      <EventLivestreamVideoPlayer event={event} alwaysShowLiveBug={true} showProgressBar={false} />
      <Link href="/events/[slug]" as={`/events/${event.slug}`}>

        <BottomCard>
          <Title>{event.name}</Title>
          <Organizer>{event.organizer?.name}</Organizer>
        </BottomCard>

      </Link>
    </StreamContainer>
  );
}

export default StreamMiniplayer
