import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import StreamPopout from '../components/stream-popout/stream-popout'
import gql from 'graphql-tag'
import { EventFragment } from '../components/events/event-fragment'

// Due to a bug in next-css, client side routing using next/link is broken
// for pages that import a .css file. A temporary workaround is to import a .css
// file on every page.
//
// https://github.com/zeit/next-plugins/issues/282
import '../styles/empty.css'
import theme from '../styles/theme'
import { ThemeProvider } from 'styled-components'
import { withApollo } from '../apollo/apollo'
import PACEvent from '../models/event/pac-event'
import { BANNER_FRAGMENT } from '../components/system/banner/banner'
import BannerModel from '../models/banner'
import BannerProvider from '../components/system/banner/banner-provider'
import HeaderProvider from '../components/header/header-provider'
import { useQuery } from '@apollo/react-hooks'
import StreamEventProvider from '../components/stream-popout/stream-provider'
import { initSentry } from '../utils/sentry'

initSentry()

const MyApp = ({
  Component,
  pageProps,
  liveEvent: ssrLiveEvent,
  banner
}: {
  Component: any
  pageProps: any
  liveEvent?: PACEvent
  banner?: BannerModel
}) => {
  Router.events.on('routeChangeComplete', url => {
    try {
      // @ts-ignore
      window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID, {
        page_location: url
      })
    } catch (error) {}
  })

  const { loading, error, data } = useQuery(
    gql`
      query LiveEventsRefresh {
        ...LiveEvents
      }
      ${LIVE_EVENTS_FRAGMENT}
    `,
    {
      pollInterval: 30000
    }
  )

  const refreshedLiveEvent = data?.liveEvents?.length ? data.liveEvents[0] : null
  const liveEvent = !!data ? refreshedLiveEvent : ssrLiveEvent
  const safeLiveEvent = !!liveEvent?.livestreamUrl ? liveEvent : null

  return (
    <ThemeProvider theme={theme}>
      <StreamEventProvider liveEvent={safeLiveEvent}>
        <BannerProvider banner={banner}>
          <HeaderProvider>
            <Component {...pageProps} />
            <StreamPopout />
          </HeaderProvider>
        </BannerProvider>
      </StreamEventProvider>
    </ThemeProvider>
  )
}

const LIVE_EVENTS_FRAGMENT = gql`
  fragment LiveEvents on Query {
    liveEvents: allEvents(limit: 1, filter: { liveNow: true, hasLivestream: true }) {
      ...PACEvent
    }
  }
  ${EventFragment}
`

const QUERY = gql`
  query GlobalPage {
    ...LiveEvents
    ...Banner
  }
  ${BANNER_FRAGMENT}
  ${LIVE_EVENTS_FRAGMENT}
`

MyApp.getInitialProps = async appContext => {
  const { apolloClient } = appContext
  const appProps = await App.getInitialProps(appContext)

  const { data, loading, errors } = await apolloClient.query({
    query: QUERY
  })

  const liveEvent: PACEvent = data?.liveEvents?.length ? data.liveEvents[0] : null
  const banner: BannerModel = data?.banner

  return { ...appProps, liveEvent, banner }
}

export default withApollo(MyApp)
