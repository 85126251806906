import breakpoints from './breakpoints'

export const colors = {
  white: '#F5F5F5',

  neutral100: '#F8F8F8',
  neutral200: '#EEEEEE',
  neutral300: '#D6D6D6',
  neutral400: '#A9A9A9',
  neutral500: '#868686',
  neutral600: '#757575',
  neutral700: '#5A5A5A',
  neutral800: '#3F3F3F',
  neutral900: '#222222',

  primary900: '#071a2a',
  primary800: '#0B243C',
  primary700: '#152E46',
  primary600: '#1B3A59',
  primary500: '#23476C',
  primary400: '#2F5A86',
  primary300: '#3C6C9D',
  primary200: '#648BB3',
  primary100: '#83A7CC',

  danger: '#ab3e39',
  success: '#32843d',
  alert: '#C7373B'
}

export const fontSizes = {
  size600: '13px',
  size500: '16px',
  size400: '20px',
  size300: '26px',
  size200: '33px',
  size100: '42px',

  lineHeight600: '22px',
  lineHeight500: '27px',
  lineHeight400: '32px',
  lineHeight300: '41px',
  lineHeight200: '51px',
  lineHeight100: '64px'
}

export const transitionTimings = {
  veryFast: '100ms',
  fast: '300ms'
}

export const fonts = {
  openSans: `'Open Sans', Arial, Helvetica, sans-serif`,
  raleway: `'Raleway', 'Open Sans', Arial, Helvetica, sans-serif`,
  tiemposHeadline: `'TiemposHeadline', serif`
}

const theme = {
  fontFamily: {
    bodyCopy: fonts.openSans,
    headline: fonts.tiemposHeadline
  },
  fontHeight: {
    bodyCopy: fontSizes.lineHeight500
  },
  colors: {
    background: colors.primary900,
    bodyBackground: colors.neutral200,

    linkColor: colors.primary500,
    linkHoverColor: colors.primary600,
    linkActiveColor: colors.primary700,
    typography: {
      dark: {
        text: colors.neutral900,
        subtext: colors.neutral700,
        subheadline: colors.neutral700,
        linkHoverColor: colors.primary600
      },
      light: {
        text: colors.white,
        subtext: colors.neutral300,
        linkHoverColor: colors.neutral400
      }
    },
    footerOverlay: colors.primary800,
    separator: colors.neutral300
  },
  transitions: {
    links: transitionTimings.fast,
    sidebar: transitionTimings.fast
  },
  spacing: {
    base100: '4px',
    base200: '7px',
    base300: '11px',
    base400: '17px',
    base500: '27px',
    base600: '44px',
    base700: '71px',
    base800: '115px'
  },
  shadows: {
    close: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    hover: '0px 8px 8px rgba(0, 0, 0, 0.1)'
  },
  breakpoints: {
    navbar: breakpoints.lg,
    venueCards: breakpoints.md
  },
  forms: {
    button: {
      backgroundColor: colors.primary600,
      hoverBackgroundColor: colors.primary700,
      color: colors.white,
      font: fonts.raleway
    }
  }
}

export default theme
