import { useRouter } from 'next/router'
import React from 'react'
import breakpoints from '../../styles/breakpoints'
import StreamMiniplayer from './stream-miniplayer'
import StreamNotification from './stream-notification'
import { useStreamEvent } from './stream-provider'
import useWindowDimensions from './use-window-dimensions'

const StreamPopout = () => {
  const liveEvent = useStreamEvent()
  const router = useRouter()
  const { width } = useWindowDimensions()

  if (!liveEvent) {
    return null
  }

  if (router.pathname === '/events/[slug]' && router.query.slug === liveEvent.slug) {
    return null
  }

  if (!width) {
    return null
  }

  const miniplayerBreakpointWidth = Number(breakpoints.md.replace('px', ''))
  return (
    <>
      {width >= miniplayerBreakpointWidth ? (
        <StreamMiniplayer event={liveEvent} />
      ) : (
        <StreamNotification event={liveEvent} />
      )}
    </>
  )
}

export default StreamPopout
