import { createContext, useContext, ReactNode } from 'react'
import PACEvent from '../../models/event/pac-event'

const StreamEventContext = createContext<PACEvent>(null)
export const useStreamEvent = () => useContext(StreamEventContext)

export const useIsLive = (event: PACEvent): boolean => {
  if (!event) {
    return false
  }

  const liveEvent = useStreamEvent()
  return liveEvent && liveEvent.id === event.id
}

const StreamEventProvider = ({
  liveEvent,
  children
}: {
  liveEvent?: PACEvent
  children: ReactNode
}) => {
  return <StreamEventContext.Provider value={liveEvent}>{children}</StreamEventContext.Provider>
}

export default StreamEventProvider
