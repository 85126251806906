enum IconType {
  Calendar,
  ExpandDown,
  ExpandUp,
  FilterReverse,
  Filter,
  Hamburger,
  MagnifyingGlass,
  RightArrow,
  Twitter,
  Facebook,
  Instagram,
  ModalClose
}

export default IconType
