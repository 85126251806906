import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 750px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 900px;
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 1000px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    max-width: 1200px;
  }
`

export default Container
