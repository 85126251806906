import React from 'react'
import IconType from './icon-type'

import Calendar from './svgs/calendar.svg'
import ExpandDown from './svgs/expand-down.svg'
import ExpandUp from './svgs/expand-up.svg'
import FilterReverse from './svgs/filter-reverse.svg'
import Filter from './svgs/filter.svg'
import Hamburger from './svgs/hamburger.svg'
import MagnifyingGlass from './svgs/magnifying-glass.svg'
import RightArrow from './svgs/right-arrow.svg'
import Twitter from './svgs/twitter.svg'
import Facebook from './svgs/facebook.svg'
import Instagram from './svgs/instagram.svg'
import ModalClose from './svgs/modal-close.svg'

export {
  Calendar,
  ExpandDown,
  ExpandUp,
  FilterReverse,
  Filter,
  Hamburger,
  MagnifyingGlass,
  RightArrow,
  IconType,
  Twitter,
  Facebook,
  Instagram,
  ModalClose
}

const Icon = ({ type, ...props }: { type: IconType }) => {
  switch (type) {
    case IconType.Calendar:
      return <Calendar {...props} />
    case IconType.ExpandDown:
      return <ExpandDown {...props} />
    case IconType.ExpandUp:
      return <ExpandUp {...props} />
    case IconType.FilterReverse:
      return <FilterReverse {...props} />
    case IconType.Filter:
      return <Filter {...props} />
    case IconType.Hamburger:
      return <Hamburger {...props} />
    case IconType.MagnifyingGlass:
      return <MagnifyingGlass {...props} />
    case IconType.RightArrow:
      return <RightArrow {...props} />
    case IconType.Twitter:
      return <Twitter {...props} />
    case IconType.Facebook:
      return <Facebook {...props} />
    case IconType.Instagram:
      return <Instagram {...props} />
    case IconType.ModalClose:
      return <ModalClose {...props} />
    default:
      return null
  }
}

export default Icon
