import { createContext, useContext, useState } from 'react'
import BannerModel from '../../../models/banner'
import Banner from './banner'

const BannerContext = createContext<BannerModel>(null)
export const useBanner = () => useContext(BannerContext)

type BannerHeightState = {
  height: number
  setHeight: (height: number) => void
}

const bannerHeightDefaults: BannerHeightState = {
  height: 0,
  setHeight: () => {}
}

export const BannerHeightContext = createContext<BannerHeightState>(bannerHeightDefaults)
export const useBannerHeight = () => {
  const { height } = useContext(BannerHeightContext)
  return height
}

const BannerProvider = ({ banner, children }: { banner?: BannerModel; children: any }) => {
  const [height, setHeight] = useState(bannerHeightDefaults.height)
  const state: BannerHeightState = { height, setHeight }

  return (
    <BannerContext.Provider value={banner}>
      <BannerHeightContext.Provider value={state}>
        <Banner />
        {children}
      </BannerHeightContext.Provider>
    </BannerContext.Provider>
  )
}

export default BannerProvider
