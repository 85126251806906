import { createContext, useContext, useState } from 'react'

type HeaderHeightState = {
  height: number
  setHeight: (height: number) => void
}

const headerHeightDefaults: HeaderHeightState = {
  height: 0,
  setHeight: () => {}
}

export const HeaderHeightContext = createContext<HeaderHeightState>(headerHeightDefaults)
export const useHeaderHeight = () => {
  const { height } = useContext(HeaderHeightContext)
  return height
}

const HeaderProvider = ({ children }: { children: any }) => {
  const [height, setHeight] = useState(headerHeightDefaults.height)
  const state: HeaderHeightState = { height, setHeight }

  return <HeaderHeightContext.Provider value={state}>{children}</HeaderHeightContext.Provider>
}

export default HeaderProvider
