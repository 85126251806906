import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import LiveBug from './live-bug'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-mux'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  padding: 56.25% 0 0 0;
  position: relative;
`

const Player = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;

  &.vjs-has-started::after {
    opacity: 1;
  }

  &.vjs-has-started.vjs-user-inactive.vjs-playing::after {
    opacity: 0;
    transition: visibility 1s, opacity 1s;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50.52%,
      rgba(0, 0, 0, 0.6) 93.75%,
      rgba(0, 0, 0, 0.75) 100%
    );
    pointer-events: none;
    z-index: 4;
    transition: visibility 0.1s, opacity 0.1s;
  }

  .vjs-live-display {
    display: none;
  }

  .vjs-control-bar {
    background-color: transparent;
    z-index: 5;
  }

  ${({ showProgressBar }: { showProgressBar: boolean }) =>
    !showProgressBar &&
    css`
      .vjs-custom-control-spacer.vjs-spacer {
        display: block;
        flex-grow: 1;
      }
    `}

  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    line-height: 150px;
    border-radius: 100%;
    border: none;
    background-color: rgba(46, 46, 46, 0.7);

    .vjs-icon-placeholder::before {
      font-size: 3em;
    }
  }

  &:hover .vjs-big-play-button,
  .vjs-big-play-button:focus {
    background-color: rgba(0, 0, 0, 1);
  }
`

const PlayerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

const Live = styled.div`
  padding: ${(props) => props.theme.spacing.base100} ${(props) => props.theme.spacing.base300};
  color: ${(props) => props.theme.colors.typography.light.text};

  text-transform: uppercase;
  font-weight: bold;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  gap: ${(props) => props.theme.spacing.base200};
  opacity: 0;
  transition: opacity 1s, transform 0.1s;

  ${({ isShowing }: { isShowing: boolean }) =>
    isShowing &&
    css`
      opacity: 1;
      transition: opacity 0.1s, transform 0.1s;
    `}
`

const VideoPlayer = ({
  src,
  posterUrl,
  alwaysShowLiveBug = false,
  showProgressBar = true,
}: {
  src: string
  posterUrl: string
  alwaysShowLiveBug?: boolean
  showProgressBar?: boolean
}) => {
  const inactivityTimeout = 2000 // ms
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasStarted, setHasStarted] = useState(false)
  const [userActive, setUserActive] = useState(false)
  const [justStarted, setJustStarted] = useState(false)
  const [isPip, setIsPip] = useState(false)
  let justStartedTimeout = null

  const isShowingControls = hasStarted && ((userActive && isPlaying) || !isPlaying || justStarted)

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current
      const initTime = Date.now()

      if (playerRef.current) {
        playerRef.current.src(src)
      } else {
        playerRef.current = videojs(video, {
          liveui: true,
          autoplay: true,
          controls: true,
          controlBar: {
            progressControl: showProgressBar ? undefined : false,
            remainingTimeDisplay: showProgressBar ? undefined : false,
          },
          sources: [
            {
              src,
              type: 'application/x-mpegURL',
            },
          ],
          preload: 'auto',
          inactivityTimeout,
          poster: posterUrl,
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: process.env.NEXT_PUBLIC_MUX_DATA_ENVIRONMENT_KEY,
                player_name: 'Native Website Player',
                player_init_time: initTime,
                video_stream_type: 'live',
              },
            },
          },
        })

        playerRef.current.one('firstplay', () => {
          setHasStarted(true)
          setJustStarted(true)

          justStartedTimeout = setTimeout(() => {
            setJustStarted(false)
            justStartedTimeout = null
          }, inactivityTimeout)
        })

        playerRef.current.on('play', () => {
          setIsPlaying(true)
        })

        playerRef.current.on('pause', () => {
          setIsPlaying(false)
        })

        playerRef.current.on('useractive', () => {
          setUserActive(true)
        })

        playerRef.current.on('userinactive', () => {
          setUserActive(false)
        })

        playerRef.current.on('enterpictureinpicture', () => {
          setIsPip(true)
        })

        playerRef.current.on('leavepictureinpicture', () => {
          setIsPip(false)

          justStartedTimeout = setTimeout(() => {
            setJustStarted(false)
            justStartedTimeout = null
          }, inactivityTimeout)
        })
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
      }

      if (justStartedTimeout) {
        clearTimeout(justStartedTimeout)
      }
    }
  }, [videoRef])

  if (playerRef.current) {
    const currentSrc = playerRef.current.src()
    if (currentSrc !== src) {
      playerRef.current.src(src)
    }
  }

  return (
    <>
      <Container>
        <div data-vjs-player>
          <Player
            ref={videoRef}
            className="video-js"
            playsInline={true}
            controls={true}
            autoPlay={true}
            showProgressBar={showProgressBar}
          />
        </div>
        <PlayerOverlay>
          <Live isShowing={(isShowingControls || alwaysShowLiveBug) && !isPip}>
            <LiveBug />
            Live
          </Live>
        </PlayerOverlay>
      </Container>
    </>
  )
}

export default VideoPlayer
