import React from 'react'
import PACEvent from '../../models/event/pac-event'
import VideoPlayer from './video-player'

const EventLivestreamVideoPlayer = ({
  event,
  alwaysShowLiveBug = false,
  showProgressBar = true,
  ...other
}: {
  event: PACEvent
  alwaysShowLiveBug?: boolean
  showProgressBar?: boolean
}) => {
  return (
    <VideoPlayer
      src={event.livestreamUrl}
      posterUrl={event.promotionImage.url}
      alwaysShowLiveBug={alwaysShowLiveBug}
      showProgressBar={showProgressBar}
      {...other}
    />
  )
}
export default EventLivestreamVideoPlayer
