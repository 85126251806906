import styled from 'styled-components'

export default styled.p`
  color: ${(props: any) => props.theme.colors.typography[props.background || 'dark'].subheadline};
  text-transform: uppercase;
  font-weight: bold;
  margin: ${props => props.theme.spacing.base200} 0;
  letter-spacing: -0.01em;
  line-height: 1.115em;
`
