import React, { useContext, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { BannerIcon } from '../../../models/banner'
import { FaExclamationCircle } from 'react-icons/fa'
import Container from '../container/container'
import { TextColor } from '../../../models/text-color'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import gql from 'graphql-tag'
import { BannerHeightContext, useBanner } from './banner-provider'

export const BANNER_FRAGMENT = gql`
  fragment Banner on Query {
    banner {
      icon
      message
      backgroundColor
      textColor
    }
  }
`

const StyledBanner = styled.section`
  background-color: ${({ backgroundColor }: { backgroundColor: string }) => backgroundColor};
  width: 100%;
`

const BannerContainer = styled(Container)`
  display: grid;
  grid-auto-flow: column dense;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.base400};

  ${({ textColor }: { textColor: TextColor }) =>
    textColor === TextColor.LIGHT
      ? css`
          &,
          *,
          *:hover {
            color: ${(props) => props.theme.colors.typography.light.text};
          }
        `
      : css`
          &,
          *,
          *:hover {
            color: ${(props) => props.theme.colors.typography.dark.text};
          }
        `}
`

const Text = styled.span`
  a {
    text-transform: unset !important;
  }
`

const Banner = () => {
  const banner = useBanner()
  if (!banner) {
    return null
  }

  const ref = useRef(null)
  const { setHeight } = useContext(BannerHeightContext)
  useEffect(() => {
    const handleResize = () => {
      const height = ref?.current?.clientHeight
      if (height) {
        setHeight(height)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const backgroundColor = `#${banner.backgroundColor.replace('#', '')}`

  return (
    <StyledBanner backgroundColor={backgroundColor} ref={ref}>
      <BannerContainer textColor={banner.textColor}>
        {banner.icon === BannerIcon.EXCLAMATION_CIRCLE && <FaExclamationCircle />}
        <Text>{documentToReactComponents(banner.message)}</Text>
      </BannerContainer>
    </StyledBanner>
  )
}

export default Banner
